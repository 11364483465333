import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function FacilityPage() {
  const { facility_id } = useParams();  // Change facilityName to facility_id
  const [facilityInfo, setFacilityInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch facility data using facility_id from the backend
    fetch(`${process.env.REACT_APP_API_URL}/facilities/${facility_id}`)  // Adjust the endpoint as per your Flask API
      .then(response => {
        if (!response.ok) {
          throw new Error("Failed to fetch facility data");
        }
        return response.json();
      })
      .then(data => {
        setFacilityInfo(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching facility:', error);
        setError(error.message);
        setLoading(false);
      });
  }, [facility_id]);

  if (loading) {
    return <p>Loading facility data...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!facilityInfo) {
    return <p>No facility information found.</p>;
  }

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{facilityInfo.facility_name}</h5>
          <h6 className="card-subtitle mb-2 text-muted">{facilityInfo.facility_type}</h6>
          <p className="card-text"><strong>Website:</strong> <a href={facilityInfo.website} target="_blank" rel="noopener noreferrer">{facilityInfo.website}</a></p>
          <p className="card-text"><strong>Address:</strong> {facilityInfo.address}</p>
          <p className="card-text"><strong>Phone Number:</strong> {facilityInfo.phone_number}</p>
        </div>
      </div>
    </div>
  );
}

export default FacilityPage;
