import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function JobPage() {
  const { job_id } = useParams();  // Use job_id from the URL params
  const [jobInfo, setJobInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch job data using job_id from the backend
    fetch(`${process.env.REACT_APP_API_URL}/employers/${job_id}`)  // Adjust the endpoint as per your Flask API
      .then(response => {
        if (!response.ok) {
          throw new Error("Failed to fetch job data");
        }
        return response.json();
      })
      .then(data => {
        setJobInfo(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching job:', error);
        setError(error.message);
        setLoading(false);
      });
  }, [job_id]);

  if (loading) {
    return <p>Loading job data...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!jobInfo) {
    return <p>No job information found.</p>;
  }

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{jobInfo.job_name}</h5>
          {/*<h6 className="card-subtitle mb-2 text-muted">{jobInfo.company_name}</h6>*/}
          <p className="card-text"><strong>Company:</strong> {jobInfo.company_name}</p>
          <p className="card-text"><strong>Location:</strong> {jobInfo.location}</p>
          <p className="card-text"><strong>Pay:</strong> ${jobInfo.pay_min} - ${jobInfo.pay_max}/year</p>
          <p className="card-text"><strong>Pay Description:</strong> {jobInfo.pay_description}</p>
          <a href={jobInfo.job_url} className="card-link btn btn-primary" target="_blank" rel="noopener noreferrer">Apply Now</a>
        </div>
      </div>
    </div>
  );
}

export default JobPage;
