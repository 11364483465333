import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function FormPage() {
  const { form_id } = useParams(); // Get form_id from URL parameters
  const [formInfo, setFormInfo] = useState(null); // State to hold form information
  const [loading, setLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null); // State to manage error

  // Function to remove HTML tags
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  useEffect(() => {
    // Fetch form data using form_id from the backend
    fetch(`${process.env.REACT_APP_API_URL}/forms/${form_id}`) // Adjust the endpoint as per your Flask API
      .then(response => {
        if (!response.ok) {
          throw new Error("Failed to fetch form data");
        }
        return response.json();
      })
      .then(data => {
        setFormInfo(data); // Set the fetched data to state
        setLoading(false); // Set loading to false after fetching
      })
      .catch(error => {
        console.error('Error fetching form:', error);
        setError(error.message); // Set error message if the fetch fails
        setLoading(false); // Set loading to false if there's an error
      });
  }, [form_id]);

  // Handle loading state
  if (loading) {
    return <p>Loading form data...</p>;
  }

  // Handle error state
  if (error) {
    return <p>Error: {error}</p>;
  }

  // If formInfo is null, handle it accordingly
  if (!formInfo) {
    return <p>No form information found.</p>;
  }

  return (
    <div className="container mt-4">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{formInfo.form_name}</h5>
          <h6 className="card-subtitle mb-2 text-muted">{formInfo.form_type}</h6>
          <p className="card-text"><strong>Type:</strong> {formInfo.form_type}</p>
          <p className="card-text"><strong>VA Administration:</strong> {formInfo.va_administration}</p>
          <p className="card-text"><strong>Form URL:</strong> <a href={formInfo.form_url} target="_blank" rel="noopener noreferrer">{formInfo.form_url}</a></p>
          <p className="card-text"><strong>Usage Description:</strong> {stripHtmlTags(formInfo.usage_description)}</p>
          
          {/*formInfo.form_url && (
            <div className="mt-4">
              <h5>How to Fill Out This Form:</h5>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe 
                  className="embed-responsive-item" 
                  src={formInfo.form_url} 
                  title={`How to fill out ${formInfo.form_name}`} 
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          )*/}
        </div>
      </div>
    </div>
  );
}

export default FormPage;
