// import bootstrap, might need to do "npm install bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './styles.css'; // styles
// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import VeteranJobs from './VeteranJobs';
import VeteranFacilities from './VeteranFacilities';
import VeteranForms from './VeteranForms';
import AboutPage from './AboutPage';
import FacilityPage from './Pages/FacilitiyPage';
import FormPage from './Pages/FormPage';
import JobPage from './Pages/JobPage';
import Navbar from './Navbar'; // Import the Navbar component

function App() {
  return (
    <Router>
      <div>
        {/* Navigation Menu */}
        <Navbar /> {/* Use the Navbar component */}

        {/* Routes */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          
          <Route path="/VeteranJobs" element={<VeteranJobs />} />
          <Route path="/VeteranJobs/:job_id" element={<JobPage />} />

          <Route path="/VeteranFacilities" element={<VeteranFacilities />} />
          <Route path="/VeteranFacilities/:facility_id" element={<FacilityPage />} />

          <Route path="/VeteranForms" element={<VeteranForms />} />
          <Route path="/VeteranForms/:form_id" element={<FormPage />} />

          <Route path="/AboutPage" element={<AboutPage />} />

          {/* <Route path="/VeteranFacilities/:facilityName" element={<FacilityPage />} /> */}
          
          {/* <Route path="/VeteranForms/:formName" element={<FormPage />} /> */}
          {/* <Route path="/VeteranJobs/:jobName" element={<JobPage />} /> */}
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;
