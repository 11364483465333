// src/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles if needed

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link className="navbar-brand ms-4" to="/">VetAssist</Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/VeteranJobs">Veteran Jobs</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/VeteranFacilities">Veteran Facilities</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/VeteranForms">Veteran Forms</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/AboutPage">About</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;