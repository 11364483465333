import React, { useState, useEffect } from 'react';
import './HomePage.css';

function HomePage() {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    // Example links
    const facilityLinks = [
      { name: 'Explore VA Facilities', url: '/VeteranFacilities' },
      { name: 'View VA Forms', url: '/VeteranForms' },
      { name: 'Find Jobs', url: '/VeteranJobs' }
    ];
    setLinks(facilityLinks);
  }, []);

  return (
    <div className="homepage-container">
      <h1 className="homepage-title">VetAssist</h1>
      <p className="homepage-description">
        Veterans in Texas are currently facing numerous challenges, including access to healthcare, education, and employment opportunities. Our mission is to provide resources and support to help veterans overcome these obstacles and improve their quality of life.
      </p>
      <p className="homepage-statistic">
        According to recent statistics, approximately 25% of veterans are unemployed compared to 21% for non-veterans. This highlights the urgent need for veteran job aid and resources for our veterans.
      </p>

      <div className="link-box">
        {links.map((link, index) => (
          <a key={index} href={link.url} className="link-button">
            {link.name}
          </a>
        ))}
      </div>

      {/* Patriotic Image */}
      <img 
        src="https://www.themoors.com/wp/wp-content/uploads/veterans-day-a-time-to-honor-and-remember-min.jpg" 
        alt="Patriotic" 
        className="patriotic-image" 
      />
    </div>
  );
}

export default HomePage;
