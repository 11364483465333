import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import EthanFangImage from './images/ethan_fang_headshot.jpg';
import NathanTranImage from './images/Nathan_Headshot.png';
import AnkitDevallaImage from './images/ankit_Headshot.jpg';
import KevinWangImage from './images/Kevin_Headshot.png';

function About() {
  const [teamMembers] = useState([
    { 
      name: "Ankit Devalla", 
      gitlabUsername: "adevalla1", 
      imageUrl: AnkitDevallaImage,
      description: "I am a junior studying computer science with a minor in business. I enjoy working on full-stack projects and learning about new technologies!"
    },
    { 
      name: "Ethan Fang", 
      gitlabUsername: "ethan.fang1", 
      imageUrl: EthanFangImage,
      description:"I am a junior studying computer science. I love building full-stack projects and also learning about the realm of ML/AI."
    },
    { 
      name: "Kevin Wang", 
      gitlabUsername: "kevinyw7", 
      imageUrl: KevinWangImage,
      description: "I am a junior computer science major with a minor in business. I am interested in full-stack development, ML, and finance!"
    },
    { 
      name: "Nathan Tran", 
      gitlabUsername: "nathantran0441", 
      imageUrl: NathanTranImage,
      description: "I am a junior computer science major with a minor in business. I am interested in full-stack development and ML!"
    }
  ]);

  const [stats, setStats] = useState({});
  const gitlabProjectId = '61905692'; // Replace with your GitLab project ID

  // Helper function to fetch all pages of data from GitLab API
  const fetchAllPages = async (baseUrl, hasQueryParams = false) => {
    let allData = [];
    let page = 1;
    let hasMore = true;

    while (hasMore) {
      const separator = hasQueryParams ? '&' : '?';
      const paginatedUrl = `${baseUrl}${separator}page=${page}&per_page=100`; // Fetch up to 100 per page
      const response = await fetch(paginatedUrl);
      if (!response.ok) throw new Error(`Failed to fetch data: ${response.statusText}`);

      const data = await response.json();
      allData = allData.concat(data);

      // Check if there are more pages based on the headers
      const linkHeader = response.headers.get('link');
      if (linkHeader && linkHeader.includes('rel="next"')) {
        page++;
      } else {
        hasMore = false;
      }
    }

    return allData;
  };

  useEffect(() => {
    const fetchGitLabStats = async () => {
      const newStats = {};

      for (let member of teamMembers) {
        try {
          // Fetch all commits from the repository
          const commitsUrl = `https://gitlab.com/api/v4/projects/${gitlabProjectId}/repository/commits`; 
          const commitsData = await fetchAllPages(commitsUrl);

          // Filter commits by this user
          const userCommits = commitsData.filter(commit => commit.author_name === member.name);

          // Fetch all closed issues from the repository
          const issuesUrl = `https://gitlab.com/api/v4/projects/${gitlabProjectId}/issues?state=closed`;
          const issuesData = await fetchAllPages(issuesUrl, true); // True because issuesUrl already has query parameters

          // Filter issues by who closed them (closed_by.username)
          const userClosedIssues = issuesData.filter(issue => issue.closed_by && issue.closed_by.username === member.gitlabUsername);

          // Store stats for this member
          newStats[member.gitlabUsername] = {
            commits: userCommits.length,
            issuesClosed: userClosedIssues.length
          };
        } catch (error) {
          console.error(`Error fetching data for ${member.gitlabUsername}:`, error);
          newStats[member.gitlabUsername] = {
            commits: 'Error',
            issuesClosed: 'Error'
          };
        }
      }

      // Update state once after processing all members
      setStats(newStats);
    };

    fetchGitLabStats();
  }, [teamMembers, gitlabProjectId]);

  return (
    <div className="container text-center"> {/* Center-align entire container */}
      <h1 className="my-4">About Us</h1> {/* Centered by text-center in the container */}
  
      <div className="mb-4">
        <p>
          This website was created to help veterans in Texas find jobs, nearby veteran facilities, and forms. 
          It addresses the challenges veterans face when transitioning to civilian life by centralizing 
          resources to make it easier to access opportunities and support systems, helping them thrive 
          in their communities.
        </p>
        <p>
          <strong>Technologies Used:</strong> React.js, Bootstrap, GitLab API, Flask, PyTest, Jest, Selenium, and Postgres!
        </p>
      </div>
  
      <div className="row justify-content-center"> {/* Centers the row contents */}
        {teamMembers.map((member) => (
          <div className="col-md-3 d-flex justify-content-center" key={member.gitlabUsername}>
            <div className="card mb-4">
              <img src={member.imageUrl} className="card-img-top" alt={`${member.name}`} />
              <div className="card-body text-center"> {/* Centers the card content */}
                <h5 className="card-title">{member.name}</h5>
                <p className="card-text">{member.description}</p>
                <p className="card-text"><strong>Commits:</strong> {stats[member.gitlabUsername]?.commits || '0'}</p>
                <p className="card-text"><strong>Issues Closed:</strong> {stats[member.gitlabUsername]?.issuesClosed || '0'}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );  
}

export default About;
